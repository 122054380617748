:root {
  --main-color: #ff1518;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Roboto", sans-serif !important;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
  color: inherit;
}
